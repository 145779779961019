import '../../service/domain/account.js';
import '../../service/domain/popup.js';
import '../../service/domain/modal.js';
import '../../service/analytics/analytics.js';
import '../../service/helper.js';
import '../../service/domain/state.js';
import '../../service/configs/analytic-events.js';

import { from, Subject } from 'rxjs';
import { exhaustMap, takeUntil } from 'rxjs/operators';

(function() {
  'use strict';

  const component = { name: 'lindaLoginForm' };

  controller.$inject = [
    '$scope',
    'account',
    'popup',
    'modal',
    '$state',
    'analytics',
    'helper',
    'state',
    'analyticsEvents',
    '$element'
  ];

  function controller($scope, _account, _popup, _modal, $state, analytics, _helper, _state, _analyticsEvents, $element) {
    const loginMode = 'login';
    const emailMode = 'email';

    const formSubmitSubject = new Subject();
    const destroySubject = new Subject();

    $scope.rememberedLogin = localStorage.getItem('rememberedLogin') || '';
    $scope.rememberedAvatar = localStorage.getItem('rememberedAvatar') || '';
    $scope.rememberedName = localStorage.getItem('rememberedName') || '';

    $scope.preloader = false;
    $scope.isLoginSuccessful = false;
    $scope.mode = null;
    $scope.credentials = {
      login: $scope.rememberedLogin === '' ? undefined : $scope.rememberedLogin,
    };

    $scope.sendOpenReg = (area) => {
      analytics.send(_analyticsEvents.createAccountButton, `login_form_${area}`);
    };

    let successRedirectState = null;

    const loginByTwoFactor = () => {
      _modal
        .open('linda-two-factor-login')
        .then((code) => {
          $scope.preloader = true;
          $scope.credentials = {
            ...$scope.credentials,
            ...code,
          };
          login();
        })
        .catch((err) => console.error(err));
    };

    this.$onInit = function() {
      successRedirectState = this.successRedirectState;
    };

    this.$onChanges = function() {
      $scope.mode = $scope.rememberedName ? loginMode : this.mode;
    };

    $scope.changeMode = () => {
      $scope.mode = $scope.mode === loginMode ? emailMode : loginMode;
    };

    $scope.resetLoginCredentials = () => ($scope.credentials.login = '');

    const login = () => {
      $scope.preloader = true;
      if ($scope.mode) {
        $scope.mode === loginMode ? delete $scope.credentials.email : delete $scope.credentials.login;
      }

      return _account.login($scope.credentials)
        .then(() => {
          _popup.close();
          $scope.isLoginSuccessful = true;

          if (angular.isFunction(_popup.cb)) {
            _popup.cb();
            _popup.cb = null;
          }

          if (successRedirectState && $state.get(successRedirectState)) {
            $state.go(successRedirectState);
          }

          if (!successRedirectState) {
            _state.readyToRedirectAfterAuth$.next();
          }
        })
        .catch((error) => {
          $scope.preloader = false;
          if (error.messages[0].code === 2091) {
            delete $scope.credentials.oneTimeCode;
            delete $scope.credentials.backupCode;
          }
          if (error.messages[0].code === 1152) {
            loginByTwoFactor();
          }
        }).finally(() => {
          $scope.preloader = false;
        });
    };

    formSubmitSubject.pipe(
      exhaustMap(event => {
        return from(login());
      }),
      takeUntil(destroySubject)
    )
    .subscribe();

    const formValidatorCallback = () => {
      formSubmitSubject.next();
    }

    $scope.submit = _helper.form.validator(formValidatorCallback)

    $scope.$onDestroy = () => {
      destroySubject.next();
      destroySubject.unsubscribe();
    };
  }

  app.component(component.name, {
    controller: controller,
    template: app.getTU(component.name),
    bindings: {
      successRedirectState: '<',
      mode: '<',
    },
  });
})();
