// src/components/qr-code/qr-code.styles.ts
import { css } from "lit";
var qr_code_styles_default = css`
  :host {
    display: inline-block;
  }
`;

export {
  qr_code_styles_default
};
