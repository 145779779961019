(function () {
  'use strict';

  const component = { name: 'lindaFormDateSelector' };

  controller.$inject = ['$scope'];

  function controller($scope) {
    function daysInMonth(month, year) {
      return new Date(parseInt(year), parseInt(month), 0).getDate();
    }

    function dayList(month, year) {
      const count = daysInMonth(month, year);
      const days = [];
      for (let i = 1; i <= count; i++) {
        days.push(i <= 9 ? `0${i}` : `${i}`);
      }
      return days;
    }

    function yearList() {
      const years = [];
      const year = new Date().getYear() + 1900 - 18;
      for (let i = year; i >= 1900; i--) {
        years.push(`${i}`);
      }
      //            for( let i = 1900; i<= year; i++ ){
      //                years.push( `${i}` );
      //            }
      return years;
    }

    $scope.birthdate = {
      day: null,
      month: null,
      year: null,
    };

    $scope.formField = {
      day: { disabled: false },
      month: { disabled: false },
      year: { disabled: false },
    };

    $scope.calendar = {
      set d(val) {
        if (val.length) this._d = val;
      },
      get d() {
        return this._d;
      },

      _d: [
        '01',
        '02',
        '03',
        '04',
        '05',
        '06',
        '07',
        '08',
        '09',
        '10',
        '11',
        '12',
        '13',
        '14',
        '15',
        '16',
        '17',
        '18',
        '19',
        '20',
        '21',
        '22',
        '23',
        '24',
        '25',
        '26',
        '27',
        '28',
        '29',
        '30',
        '31',
      ],
      m: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
      y: yearList(),
    };

    this.$onInit = () => {
      if (typeof this.ngDisabled === 'boolean') {
        if (this.ngDisabled === true) {
          $scope.formField = {
            day: { disabled: true },
            month: { disabled: true },
            year: { disabled: true },
          };
        } else if (this.ngDisabled === false) {
          $scope.formField = {
            day: { disabled: false },
            month: { disabled: false },
            year: { disabled: false },
          };
        }
      }

      if (this.ngModel) {
        $scope.birthdate.day = this.ngModel.split('-')[2];
        $scope.birthdate.month = this.ngModel.split('-')[1];
        $scope.birthdate.year = this.ngModel.split('-')[0];
      }

      $scope.calendar.d = dayList($scope.birthdate.month, $scope.birthdate.year);

      $scope.$watch('birthdate.day', (newVal, oldVal) => {
        this.ngModel = `${$scope.birthdate.year}-${$scope.birthdate.month}-${$scope.birthdate.day}`;
      });

      $scope.$watch('birthdate.month', (newVal, oldVal) => {
        $scope.calendar.d = dayList($scope.birthdate.month, $scope.birthdate.year);
        if (parseInt($scope.birthdate.day) > parseInt($scope.calendar.d[$scope.calendar.d.length - 1])) {
          $scope.birthdate.day = $scope.calendar.d[$scope.calendar.d.length - 1];
        }
        this.ngModel = `${$scope.birthdate.year}-${$scope.birthdate.month}-${$scope.birthdate.day}`;
      });

      $scope.$watch('birthdate.year', (newVal, oldVal) => {
        $scope.calendar.d = dayList($scope.birthdate.month, $scope.birthdate.year);
        if (parseInt($scope.birthdate.day) > parseInt($scope.calendar.d[$scope.calendar.d.length - 1])) {
          $scope.birthdate.day = $scope.calendar.d[$scope.calendar.d.length - 1];
        }
        this.ngModel = `${$scope.birthdate.year}-${$scope.birthdate.month}-${$scope.birthdate.day}`;
      });
    };
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
    bindings: {
      ngModel: '=',
      ngDisabled: '=',
    },
  });
})();
