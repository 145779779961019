import '../../service/domain/http.js';
import '../../service/rx/notification$.js';
import '../../service/domain/icon.js';
import '../../service/configs/config.js';
import '../../service/domain/tournament.js';
import '../../service/domain/vip.js';
import '../../service/rx/system$.js';
import '../../service/rx/ws$.js';
import '../../service/domain/user.js';
import '../../service/configs/ws-events.js';
import { filter, tap, share } from 'rxjs/operators';

(function () {
  'use strict';

  const UPDATE_EVENT = 'league:update';
  const HISTORY_UPDATE_EVENT = 'league:HistoryUpdate';

  class EventBus extends EventTarget {
    leagueData = null;
    historyData = null;

    update(data) {
      this.leagueData = data;
      this.dispatchEvent(new CustomEvent(UPDATE_EVENT, { detail: this.leagueData }));
    }

    updateHistory(data) {
      this.historyData = data;
      this.dispatchEvent(new CustomEvent(HISTORY_UPDATE_EVENT, { detail: this.historyData }));
    }
  }

  controller.$inject = [
    'http',
    'notification$',
    'icon',
    'config',
    'tournament',
    'vip',
    'user',
    'system$',
    'ws$',
    'wsEvents',
  ];

  function controller(_http, _notification$, _icon, _config, _tournament, _vip, _user, _system$, _ws$, _wsEvents) {
    let icons = [];
    let vip = [];
    let league = null;

    const LEAGUE_ENABLED = _config?.feature?.league_tournament?.visible;
    let bus = null;

    let inQualPeriod = true;
    let inProgress = true;

    async function loadFastList() {
      inProgress = true;
      return Promise.all([
        _icon.collection({ category: 'league' }),

        _tournament.fastTournamentList({ strategy: 'league', limit: 100 }),
        _vip.collection(),
      ]).then(([iconsRaw, leagueRaw, vipRaw]) => {
        icons = iconsRaw.result;
        vip = vipRaw.result;

        league = leagueRaw.result[0];

        inQualPeriod = league.isQualificationPeriod;
        inProgress = false;

        if (league == undefined) {
          return Promise.resolve(null);
        }

        return _tournament.fastTournamentGetLeaderboard(league.tournamentEventId, { limit: 100 }).then((data) => {
          const result = transformLeague(league, data, vip, icons);
          bus.update(result);
          return result;
        });
      });
    }

    let initLeagueWsEvent;
    let wsCallback;

    if (LEAGUE_ENABLED) {
      bus = new EventBus();
      _system$
        .pipe(filter((message) => ['login', 'logout'].includes(message.action)))
        .subscribe((message) => {
          if (!league) {
            return;
          }
          _tournament.fastTournamentGetLeaderboard(league.tournamentEventId, { limit: 100 }).then((data) => {
            const result = transformLeague(league, data, vip, icons);
            bus.update(result);
          });
        });

      wsCallback = ({ data }) => {
        if (!league) {
          return;
        }
        const updatedLeaguePoints = data.leaguePoints;
        _tournament.fastTournamentGetLeaderboard(league.tournamentEventId, { limit: 100 }).then((data) => {
          data.result.leaguePoints = updatedLeaguePoints;
          const result = transformLeague(league, data, vip, icons);
          bus.update(result);
        });
      };

      const socket = _ws$
        .pipe(
          filter(({ event }) => event === _wsEvents.fastTournamentUpdateLeaderboard),
          tap(wsCallback),
          share()
          );

      initLeagueWsEvent = function () {
        return socket;
      };

      loadFastList();

      bus.addEventListener(UPDATE_EVENT, (e) => {
        if (inProgress) return;
        let end;
        if (inQualPeriod) {
          end = new Date(e.detail.qualificationPeriodEnd);
        } else {
          end = new Date(e.detail.endTime);
        }
        const now = Date.now();
        const timeEnds = end.getTime() <= now;
        if (!timeEnds) return;
        loadFastList().then((result) => {});
      });
    }

    return {
      onUpdate(fn) {
        if (!LEAGUE_ENABLED) {
          return console.error('Enable league in config first');
        }

        if (bus.leagueData) {
          fn(bus.leagueData);
        }

        bus.addEventListener(UPDATE_EVENT, (e) => {
          fn(e.detail);
        });
        return initLeagueWsEvent();
      },
      onHistoryUpdate(fn) {
        if (!LEAGUE_ENABLED) {
          return console.error('Enable league in config first');
        }

        if (bus.historyData) {
          fn(bus.historyData);
        }

        bus.addEventListener(HISTORY_UPDATE_EVENT, (e) => {
          fn(e.detail);
        });
      },
      requestHistoryData() {
        if (_user.status) {
          _tournament.fastTournamentHistory({ strategy: 'league' }).then((historyData) => {
            bus.updateHistory(transformHistory(historyData, league.prizes, vip, icons));
          });
        }
      },
      getHistory() {
        return bus.historyData || [];
      },

      claimReward(rewardId) {
        return _tournament.leagueClaimReward({
          tournamentEventId: bus.historyData[0].tournamentEventId,
          rewardId,
        });
      },
    };
  }

  app.factory('league', controller);

  function timeDiffCalc(dateFuture, dateNow) {
    let diffInMilliSeconds = Math.abs(dateFuture - dateNow) / 1000;

    const days = Math.floor(diffInMilliSeconds / 86400);
    diffInMilliSeconds -= days * 86400;

    const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
    diffInMilliSeconds -= hours * 3600;

    const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
    diffInMilliSeconds -= minutes * 60;

    let difference = '';
    if (days > 0) {
      // difference += (days === 1) ? `${days} day, ` : `${days} days, `;
      difference += `${days}d, `;
    }

    difference += `${hours}h`;
    // difference += (hours === 0 || hours === 1) ? `${hours} hour, ` : `${hours} hours, `;

    if (days === 0) {
      difference += `${minutes}m`;
    }
    // difference += (minutes === 0 || hours === 1) ? `${minutes} minutes` : `${minutes} minutes`;

    return difference;
  }

  function transformHistory({ result }, prizesRaw, vip, icons) {
    // return transformLeaderBoard(result, prizesRaw, vip, icons);
    return result.map((week) => {
      const prize = prizesRaw.find((prize) => prize.place === week.league);
      return {
        ...week,
        current: week.league,
        league: 9 - week.league,
        prize: transformPrize(prize, vip, icons),
      };
    });
  }

  function transformPlayer(player) {
    if (!player) {
      return null;
    }
    return {
      ...player,
      current: 9 - player.data.league,
    };
  }

  function transformPrizeOption(option, icons) {
    const icon = icons.find(({ code }) => code === option.code);
    return {
      icon: icon?.logo,
      title: icon?.title,
      ...option,
    };
  }

  function transformPrize(prize, vip, icons) {
    const levelPrize = prize?.mandatory?.find(({ type }) => type === 'LEVEL');
    const level = vip.find(({ level }) => level === (levelPrize?.level || 1));
    return {
      instant: prize?.instant.map((option) => transformPrizeOption(option, icons))[0],
      level,
      // options: prize?.mandatory.map(option => transformPrizeOption(option, icons)),
      mandatory: prize?.mandatory
        .filter((i) => i.type !== 'LEVEL')
        .map((option) => transformPrizeOption(option, icons)),
      raffled: prize?.mandatory
        .filter((i) => i.type === 'OFFLINE')
        .map((option) => transformPrizeOption(option, icons))[0],
    };
  }

  function transformLeaderBoard(leaderBoard, prizesRaw, vip, icons) {
    const prizes = prizesRaw.reduce((acc, { place, ...rest }) => {
      acc[place] = transformPrize(rest, vip, icons);

      return acc;
    }, {});

    const places = leaderBoard.reverse().reduce(
      (result, el) => {
        const place = 9 - el.data.league;

        result[place].push({
          ...el,
          prize: prizes[place],
        });

        return result;
      },
      {
        1: [],
        2: [],
        3: [],
        4: [],
        5: [],
        6: [],
        7: [],
        8: [],
      },
    );

    return {
      places: Object.entries(places),
      playersCount: leaderBoard.length,
      prizes,
    };
  }

  function transformLeaguePoints(leaguePoints) {
    if (leaguePoints == undefined) {
      leaguePoints = [];
    }
    if (leaguePoints.length < 8) {
      const points = Array(8)
        .fill(0)
        .map((val, i, arr) => {
          let point = leaguePoints.filter((item) => item.league === i + 1)[0];
          if (point == undefined) {
            point = { league: i + 1, empty: true };
          } else {
            point.empty = false;
          }
          return point;
        });
      leaguePoints = points;
    }
    leaguePoints.sort((a, b) => a.league - b.league);
    return leaguePoints;
  }

  function transformLeague(
    { prizes, ...league },
    { result: { leaderBoard, currentPlayer, leaguePoints } },
    vip,
    icons,
  ) {
    return {
      ...league,
      liveUntil: timeDiffCalc(league.endTime, Date.now()),
      isQualification: leaderBoard.length ? false : true,
      currentPlayer: transformPlayer(currentPlayer),
      leaderBoard: transformLeaderBoard(leaderBoard, prizes, vip, icons),
      leaguePoints: transformLeaguePoints(leaguePoints),
    };
  }
})();
