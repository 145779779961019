(function () {
  'use strict';

  controller.$inject = [];

  function controller() {

    return {
      customValidators(scope, element, attrs, ngModel, ngModelCtrl) {

        ngModel.$validators.required = (modelValue, viewValue) => {
          const value = modelValue || viewValue;
          return !!value;
        };

        ngModel.$validators.maxlength = (modelValue, viewValue) => {
          const value = modelValue || viewValue;
          return value ? value.length <= 100 : true;
        };

        ngModel.$validators.pattern = (modelValue, viewValue) => {
          const value = modelValue || viewValue;
          return value ? /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9-]+)*$/.test(value) : false;
        };

      },
    };
  }

  app.factory('email', controller);
})();
